@font-face {
  font-family: "Bold";   /*Can be any text*/
  src: local("HonestSans-Bold"),
    url("./fonts/HonestSans-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Medium";   /*Can be any text*/
  src: local("CalibreMedium"),
    url("./fonts/HonestSans-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Regular";   /*Can be any text*/
  src: local("HonestSans-Regular"),
    url("./fonts/HonestSans-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Semibold";   /*Can be any text*/
  src: local("HonestSans-Semibold"),
    url("./fonts/HonestSans-SemiBold.otf") format("opentype");
}
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: 'Bold'; */
  /* background-color: #f0f0f0; */
}

body {
  background-color: #242424; /* Replace with your desired background color */
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-image: url('/images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url('/images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('/images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

/* Media Queries for Responsiveness */

/* Tablet */
@media screen and (max-width: 768px) {
  .home,
  .services,
  .products,
  .sign-up {
    font-size: 2rem; /* Adjust font size for tablets */
  }

  .services,
  .products,
  .sign-up {
    font-size: 60px; /* Adjust specific large font sizes */
  }
}

/* Mobile */
@media screen and (max-width: 480px) {
  .home,
  .services,
  .products,
  .sign-up {
    font-size: 1.5rem; /* Further adjust font size for mobile */
  }

  .services,
  .products,
  .sign-up {
    font-size: 40px; /* Further adjust specific large font sizes */
  }
}
